var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Месяц ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Доход ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Расход ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Итого ")])])]),_c('tbody',[_vm._l((_vm.months),function(monthData,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.monthsMap[monthData.month - 1]))]),_c('td',{staticClass:"income"},[_vm._v(_vm._s(_vm.formatPrice(monthData.income)))]),_c('td',{staticClass:"expense"},[_vm._v(_vm._s(_vm.formatPrice(monthData.expense)))]),_c('td',{class:{
            'income':(monthData.income-monthData.expense) >= 0,
            'expense':(monthData.income-monthData.expense) < 0
          }},[_vm._v(" "+_vm._s(_vm.formatPrice(monthData.income - monthData.expense))+" ")])])}),_c('tr',[_c('td',[_c('strong',[_vm._v("Итого")])]),_c('td',{staticClass:"income"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.totalIncome))+" ")]),_c('td',{staticClass:"expense"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.totalExpense))+" ")]),_c('td',{class:{
            'income':(_vm.totalIncome-_vm.totalExpense) >= 0,
            'expense':(_vm.totalIncome-_vm.totalExpense) < 0
          }},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.totalIncome - _vm.totalExpense))+" ")])])],2)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }